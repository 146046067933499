module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KN2MWG6","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HomeCare by ProMaid แม่บ้านมืออาชีพ","short_name":"HomeCare by ProMaid แม่บ้านมืออาชีพ","start_url":"/","description":"บริการแม่บ้านทำความสะอาดรายชั่วโมง รายวัน จองง่ายๆ ไม่ต้องโหลดแอป ทำความสะอาดบ้าน คอนโด หรือบริษัท ได้มาตรฐานจากประสบการณ์กว่า 18 ปี","lang":"th","display":"standalone","icon":"src/assets/images/promaid/homecare-promiad-2.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"943894d0214a1741314a8adb92995783"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1140,"quality":90,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
